<template>
  <section>
    <pm-Breadcrumb :home="home" :model="items_bread" />

    <div class="row">
      <div class="col-md-7"> <h2 class="titulo-links-escolha">Disciplina</h2></div>
      <div class="col-md-5"  v-if="situacaoAno &&  acesso.cadastrar">
        <pm-Button type="button" style="float: right;margin-top: 30px;"
          @click="registar()" label="CADASTRAR DISCIPLINA"
          class="p-button-success"
        ></pm-Button>
      </div>
    </div>

    <div class="pm-card"  v-if="acesso.visualizar">
      <pm-DataTable :value="disciplinaALL"   dataKey="id" :rowHover="true" filterDisplay="menu" v-model:filters="filters"
        :globalFilterFields="['nome', 'carga_horaria']" responsiveLayout="scroll">
          <template #header>
            <div class="flex justify-content-center align-items-center">
                <span class="p-input-icon-left ">
                  <i class="pi pi-search" />
                  <pm-InputText v-model="pesquisa" @input="pesquisar" placeholder="Pesquisar Nome"/>
                </span>
            </div>
          </template>
          <pm-Column field="nome" sortable header="NOME" style="font-size: 14px;vertical-align:middle !important"></pm-Column>
          <pm-Column field="carga_horaria" sortable header="CARGA HORÁRIA" style="font-size: 14px;vertical-align:middle !important"></pm-Column>
          <pm-Column field="id"  headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible;width: 357px;vertical-align:middle !important">
            <template #body="{data}">
              <pm-Button type="button" @click="editarDisciplina(data.id)" icon="pi pi-pencil"
               label="Editar" title="Editar" v-if="situacaoAno && acesso.editar" class="p-button-sm btn-color;table_style"></pm-Button>
              <pm-Button title="Apagar" type="button" v-if="situacaoAno && acesso.deletar" @click="deletarDisciplina(data.id)" label="Apagar"
              icon="pi pi-times-circle" class="p-button-danger p-button-sm btn-color;table_style" style="margin-left:5px"></pm-Button>
            </template>
          </pm-Column>
      </pm-DataTable>
      <div class="p-paginator p-component p-paginator-bottom" >
        <div class="p-paginator-pages" style="display: inline-flex;">
          <div  v-for="n in links" :key="n">
            <button class="p-paginator-next p-paginator-element p-link" @click="paginas(n)" v-if="!n.active"
            style="border: 0px solid #ebebeb;border-radius: 0;">
              <p class="p-paginator-icon " v-html="n.label"  style="margin-bottom:0px !important;"></p>
            </button>
            <button class="p-paginator-page p-paginator-element p-link p-highlight" @click="paginas(n)" v-if="n.active" style="border-radius: 0;">{{n.label}}</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import { defineComponent } from "vue";
import { DisciplinaEscolar } from "@/class/disciplina.js";
import { Verificar } from "@/class/verificar.js";
import Util from "@/utils/rota";

export default defineComponent({
  data() {
    const columns = [
      { key: "nome", label: "Nome", sortable: true },
      { key: "carga_horaria", label: "Carga Horária", sortable: true },
      { key: "st", label:"Status" },
      { key: "id", label: "Ações" },
    ];
    return {
      funcao:0,
      filters:'',
      home: {icon: 'pi pi-home', to: '#'},
      items_bread: [
        {label: 'Disciplinas'},
      ],
      situacaoAno:1,
      filter: '',
      disciplinaALL: [],
      columns,
      perPage: 20,
      currentPage: 1,
      status: [],
      pesquisa:null,
      links:[],
      acesso : {
        visualizar:1,
        cadastrar:1,
        editar:1,
        deletar:1,
      }
    };
  },
  computed: {
    pages() {
      return this.perPage && this.perPage !== "0"
        ? Math.ceil(this.disciplinaALL.length / this.perPage)
        : this.disciplinaALL.length;
    },
  },
  async beforeMount() {
    this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));
    await this.logado();
    await this.listarTudo();
    let acesso = await Verificar.AcessoLocal(6);
    this.acesso.visualizar = acesso.visualizar;
    this.acesso.cadastrar = acesso.cadastrar;
    this.acesso.editar = acesso.editar;
    this.acesso.deletar = acesso.deletar;
  },
  methods: {
    async logado(){
      const token = sessionStorage.getItem("token");
      const util = new Util();
      const data = await util.WhoIam(token);
      this.funcao = data.funcao;
    },
    async pesquisar(){
      try{
      if(this.pesquisa != null && this.pesquisa != ""){
        let data = await DisciplinaEscolar.pesqDisciplinaCompleto(this.pesquisa);
        this.disciplinaALL = [];
        this.links = [];

        let index = 0;
        for (const el of data.data) {
          let st = true;

          if(el.status == 1){st = true}else{st =false}
          if(el.id != 1 && el.id != 2){
              let novo = {
                id: el.id,
                nome: el.nome,
                status: el.status,
                st:{
                  index: index,
                  id: el.id,
                }
              }

            this.status.push(st);
            this.disciplinaALL.push(novo);
            index++;
          }
        }
        this.disciplinaALL.reverse();
      }else{
        this.listarTudo();
      }
      }catch(e){
        // console.log(e)
      }
    },

    async paginas(n){
      if(n.url != null){
        if(!n.active){
          let res = await axios.get(n.url);
          this.disciplinaALL =[];
          //this.disciplinaALL = res.data.data;
          this.links = res.data.links;
          let index = 0;
          for (const el of res.data.data) {
            let st = true;

            if(el.status == 1){st = true}else{st =false}
            if(el.id != 1 && el.id != 2){
              let novo = {
                id: el.id,
                nome: el.nome,
                carga_horaria: el.carga_horaria,
                status: el.status,
                st:{
                  index: index,
                  id: el.id,
                }
              }

              let sst ={
                index: index,
                id: el.id,
              }
              this.status.push(st);
              this.disciplinaALL.push(novo);
              index++;
            }
          }
          this.disciplinaALL.reverse();
        }
      }
    },
    editarDisciplina(id) {
      if(this.funcao == 1 || this.funcao == 2 || this.funcao == 7){
        this.$router.push({ name: "disciplinaEditar", params: { id: id } });
      }else{
        this.$router.push({ name: "coordenacao-disciplina-edit", params: { id: id } });
      }
    },
    registar() {
      if(this.funcao == 1 || this.funcao == 2 || this.funcao == 7){
        this.$router.push({ name: "disciplinaCadastro" });
      }else{
        this.$router.push({ name: "coordenacao-disciplina-add" });
      }
    },

    async deletarDisciplina(id){
      try {
        if (window.confirm("Você realmente deseja apagar a disciplina?")) {
          let data = await DisciplinaEscolar.remover(id);
          this.disciplinaALL = [];
          this.listarTudo();
        }
      } catch (e) {
        let a = e.response.data.error;
         this.$vaToast.init({
            message: a,
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 4000,
            fullWidth: false,
            color: 'danger'
        });
        return false;
      }
    },
    async mudarStatus(id,index){
      try {
        let st = 0;
        if(this.status[index] == true) {
          st = 0;
        }
        else {
          st = 1;
        }

        let payload = {
          id:id,
          status: st,
        };
        let data = await DisciplinaEscolar.alterarStatus(payload);
        this.disciplinaALL = [];
        this.listarTudo();
      } catch (e) {

      }
    },
    async listarTudo() {
      try {
        //let data = await DisciplinaEscolar.obtemTodos();
        let data = await DisciplinaEscolar.obtemTodosPag(this.currentPage);
        this.links = data.data.links;
        let index = 0;
        for (const el of data.data.data) {
          let st = true;

          if(el.status == 1){st = true}else{st =false}
          if(el.id != 1 && el.id != 2){
            let novo = {
              id: el.id,
              nome: el.nome,
              carga_horaria: el.carga_horaria + ' Horas',
              status: el.status,
              st:{
                index: index,
                id: el.id,
              }
            }

            let sst ={
              index: index,
              id: el.id,
            }

            this.status.push(st);
            this.disciplinaALL.push(novo);
            index++;
          }
        }
        this.disciplinaALL.reverse();
      } catch (e) {}
    },
  },
});
</script>

<style lang="scss" scoped>
.table-example--pagination {
  padding-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}
</style>
